<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <form @submit.prevent="handleSubmit">
            <div class="row gy-2 px-2">

              <div class="col-12" v-show="type == 'item' && projectAccountLevel == 'account'">
                <label class="form-label" for="linked_item">Linked Product or Service</label>
                <div>
                  <v-select
                      placeholder="Select Product"
                      v-model="form.linked_item_id"
                      :options="items"
                      label="text"
                      :reduce="text => text.id"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12" v-show="type != 'item' && projectAccountLevel == 'account'">
                <label class="form-label" for="linked_item">Linked Account Head</label>
                <div>
                  <v-select
                      placeholder="Select Account Head"
                      v-model="form.linked_account_id"
                      :options="accountHeads"
                      label="name"
                      :reduce="name => name.id"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="linked_item">Name</label>
                <div class="input-group">
                  <input type="text"
                         class="form-control form-control-sm"
                         name="name"
                         v-model="form.name"
                  >
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="linked_item">Code</label>
                <div class="input-group">
                  <input type="text"
                         class="form-control form-control-sm"
                         name="code"
                         v-model="form.code"
                  >
                  <ErrorMessage name="code" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="linked_item">Description</label>
                <div class="input-group">
                  <input type="text"
                         class="form-control form-control-sm"
                         name="description"
                         v-model="form.description"
                  >
                  <ErrorMessage name="description" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12" v-if="!showParent">
                <label class="form-label" for="exp_account_type">Exp Account Type</label>
                <div>
                  <select class="form-control" v-model="form.exp_account_type" id="exp_account_type">
                    <option value="expense">Expense</option>
                    <option value="income">Income</option>
                  </select>
                  <ErrorMessage name="exp_account_type" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12" v-show="type != 'item' && projectAccountLevel == 'account'">
                <label class="form-label" for="linked_item">Linked FD6 Account Head</label>
                <div>
                  <v-select
                    placeholder="Select FD6 Account Head"
                    v-model="form.fd6_account_id"
                    :options="fd6_account_heads"
                    label="name"
                    :reduce="name => name.id"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-12" v-show="type != 'item' && projectAccountLevel == 'account'">
                <label class="form-label" for="linked_item">Linked Program</label>
                <div>
                  <v-select
                    placeholder="Select Program"
                    v-model="form.program_id"
                    :options="programs"
                    label="name"
                    :reduce="name => name.id"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div v-if="showParent" class="col-md-12">
                <label class="form-label">Parent Group</label>
                <v-select
                    v-model="form.project_account_id"
                    label="name"
                    :options="accounts"
                    :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handlePurchaseAndSales from "@/services/modules/purchase";
import handleInventory from "@/services/modules/inventory";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleProjects from "@/services/modules/procurement/fd6";

export default {
  name: "AccountGroupModal",

  props: {
    modalTitle: {
      type: String,
    },
    form: {
      type: Object,
    },
    showParent: {
      type: Boolean,
    },
    query: {
      type: String,
    },
    types: {
      type: Object,
    },
    accountType: {
      type: String,
    },
    projectId: {
      required: true,
    },
    accounts: {
      type: Array,
    },
    projectAccountLevel: {
      type: String,
    },
    isFD6: {
      type: Boolean,
      default: false
    },
    ngoDefaultAccountTypes: {
      type: Array,
      default: []
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,
    items: [],
    accountHeads: [],
    fd6_account_heads: [],
    programs: [],
  }),

  computed: {
    typeArr() {
      return this.types ? Object.entries(this.types).map((el) => {
        return {
          id: el[0],
          name: el[1],
        }
      }) : [];
    },

    companyId() {
      return this.$route.params.companyId;
    },

    type() {
      return this.form.project_account_type;
    },
  },

  methods: {
    toggleModal() {
      if (this.isOpen) {
        this.$emit("onCloseGroupModal")
      } else {
        this.form.project_account_type = this.accountType;
        this.getProducts();
      }
      this.isOpen = !this.isOpen
    },

    async handleSubmit() {
      this.$emit('onAccountSave');
    },

    async getItems() {
      const res = await this.fetchProductList(this.query);
      if (res.data) {
        this.items = res.data;
      }
    },

    async getAccountHeads() {
      const res = await this.fetchAccountHeads(this.companyId);
      if (res.data) {
        this.accountHeads = res.data;
      }
    },

    async getFD6AccountHead() {
      const query = `?company_id=${this.companyId}`
      const res = await this.fetchFD6AccountsAll(query);
      if (res.data) {
        this.fd6_account_heads = res.data;
      }
    },

    async getPrograms() {
      let query = `?company_id=${this.companyId}`
      const res = await this.fetchProgramList(query);
      if (res.data) {
        this.programs = res.data;
      }
    },

    getProducts() {
      if (this.type == 'item') {
        this.getItems();
      } else {
        this.getAccountHeads();
        this.getFD6AccountHead();
        this.getPrograms();
      }
    },

    getBudgetHead(value, products) {
      if (this.form.name) return;

      const findProduct = products.find(el => el.id == value);
      if (findProduct) {
        this.form.name = findProduct.hasOwnProperty('text') ? findProduct.text : findProduct.name;
      } else {
        this.form.name = '';
      }
    },
  },

  watch: {
    'form.linked_item_id': function (newVal) {
      if (!newVal) return;
      this.getBudgetHead(newVal, this.items);
    },
    'form.linked_account_id': function (newVal) {
      if (!newVal) return;
      this.getBudgetHead(newVal, this.accountHeads);
    },
    'form.project_account_type': function (newVal) {
      this.getProducts();
      if (newVal == 'item') {
        this.form.linked_account_id = "";
      } else {
        this.form.linked_item_id = "";
      }
    }
  },

  setup() {
    const {fetchProductList} = handlePurchaseAndSales();
    const {fetchAccountHeads} = handleInventory();
    const {fetchProgramList} = handleNGOPrograms();
    const {fetchFD6AccountsAll} = handleProjects();

    return {
      fetchProductList,
      fetchAccountHeads,
      fetchProgramList,
      fetchFD6AccountsAll,
    }
  },
}
</script>

<style scoped>

</style>
